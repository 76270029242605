import React from "react";
import { Content } from "patterns/Content";
import { ContentBlock } from "patterns/ContentBlock";
import { MastheadNewsletter } from "patterns/MastheadNewsletter";
import { Shell } from "patterns/Shell";

import "../../scss/main.scss";

function NewsletterIssue6({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            pageDescription="My first freelancing failure..."
            pageSocialImage=""
            pageTitle="Issue #6"
            pathname={pathname}
        >
            <MastheadNewsletter
                authorImage={{
                    alt: "",
                    dimensions: {
                        height: 500,
                        width: 500,
                    },
                    url: "https://images.prismic.io/robsimpson/e65250e1-4617-44f6-b54a-a5034f29dda7_avatar.png?auto=compress,format"
                }}
                authorName="Rob Simpson"
                title="Issue #6"
            />
            <div className="container-large">
                <Content
                    content={[
                        {spans: [], text: "May has been a strange month – going from rushed off my feet to feeling the effects of burnout and trying to get back into work again after holiday.", type: "paragraph"},
                        {spans: [], text: "This newsletter has helped me more than ever this month – simply writing down my thoughts, feelings and reflecting on what has happened is a massive help to me.", type: "paragraph"},
                        {spans: [], text: "Whether you share it publicly or not, I think we can all benefit from getting things out of our heads – whether that’s writing it down or chatting to people.", type: "paragraph"},
                        {spans: [], text: "So let’s get into one of my first failures as a freelancer...", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🗓 Looking back at May"
                    content={[
                        {spans: [], text: "👷 Work", type: "heading4"},
                        {spans: [], text: "I started the month with some bad news. On my first day of holiday, I received an email – myself and the agency who hired me had been fired from a project.", type: "paragraph"},
                        {spans: [], text: "This was completely out of the blue considering the project was practically finished. So what happened?", type: "paragraph"},
                        {spans: [], text: "Logistically it was a nightmare of a project, there were 4 different parties involved, what had been sold into the client was not what was initially agreed or portrayed to us, on top of that the client was very difficult to manage (one thing I didn’t have to deal with thankfully).", type: "paragraph"},
                        {spans: [], text: "It turns out the design agency who outsourced the development to the agency who then hired me, get a lot of other business from the end client and so the project had to be perfect (which their budget just didn’t stretch to the level of what they were expecting it seems).", type: "paragraph"},
                        {spans: [], text: "We were fired because the animations didn’t match the examples they sent over – even though they were pretty much what they’d sent over. It seems they weren’t even willing to provide more detailed feedback instead they went straight to firing us.", type: "paragraph"},
                        {spans: [], text: "Reflecting on it, there were other reasons involved, such as out of scope work not being relayed back to the end client and me being on holiday – all of which was communicated early but likely didn’t bode well with the end client (it sounds like they’re used to getting their way).", type: "paragraph"},
                        {spans: [], text: "I’ve come to terms with the outcome, I went above and beyond on what was initially agreed (as well as working evenings for 3 weeks). Now that it’s all over, it’s a relief – as I would’ve stood to lose even more money on the project and put an even bigger strain on my schedule.", type: "paragraph"},
                        {spans: [], text: "The agency that hired me is still happy to work with me on future projects and we’re continuing to work on an existing project together.", type: "paragraph"},
                        {spans: [], text: "Because of that news, my schedule was all over the place when I got back from holiday. However, I diversified my work into lots of different clients which meant I still had plenty of client work to get on with when I got back.", type: "paragraph"},
                        {spans: [], text: "🏖 Holiday", type: "heading4"},
                        {spans: [], text: "Thankfully my week away wasn’t overshadowed by this news.", type: "paragraph"},
                        {spans: [], text: "I don’t think I’ve ever needed a holiday so much in my life before, it was great to just switch off from everything and enjoy some time away with my family.", type: "paragraph"},
                        {spans: [], text: "Even though a lot was closed and the weather wasn’t great, we enjoyed a lot of what Cornwall had to offer – the zoo, seal sanctuary, beautiful gardens and more.", type: "paragraph"},
                        {spans: [], text: "On return, it’s made me realise I need to slow down a little bit – being overrun with work wasn’t the reason I got into this in the first place.", type: "paragraph"},
                        {spans: [], text: "💻 Website progress", type: "heading4"},
                        {spans: [], text: "I managed to fit a bit of time in this month to work on my website too – designing up the remaining assets and even building a new contact page.", type: "paragraph"},
                        {spans: [], text: "All that’s left to do now for my MVP is drop the storybook pages into a Gatsby project and deploy. My aim is to make June the month that I finally launch my new website (having had my current one since around 2019 – not bad considering much of the design is left untouched).", type: "paragraph"},
                        {spans: [], text: "🍭 Sugar", type: "heading4"},
                        {spans: [], text: "One change I decided to make this month, was to reduce my sugar consumption. Since working from home since last March, it’s been easier than ever to snack on the wrong food, so I decided on my return from holiday to give up as much processed sugary food as possible.", type: "paragraph"},
                        {spans: [], text: "A simple swap was to replace sweets with things like tomatoes, kiwis, blueberries, nuts etc. and it’s been easier than I thought.", type: "paragraph"},
                        {spans: [], text: "🌍 Reflection", type: "heading4"},
                        {spans: [], text: "I’ve spent a bit of time this month reflecting on things, for many years I’ve had this goal to go freelance – but what’s next.", type: "paragraph"},
                        {spans: [], text: "I’m 9 months into freelancing and I’ve calculated I will earn somewhere between £70k and £75k in my first year. This was something I always knew was possible, but I also questioned whether I’d be able to do it (especially in my first year).", type: "paragraph"},
                        {spans: [], text: "Whilst it’s been great to see such growth in my business this past year, I also want to diversify my income. So I plan on taking some consistent time out of my schedule to work on side project(s) that’ll hopefully earn me some passive income down the line... and learn a ton along the way.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 May goals"
                    checkList={[
                        {complete: true, text: "👨‍🍳 Meal prep every Sunday"},
                        {complete: false, text: "🏊‍♂️ Swim once every other week"},
                        {complete: false, text: "🏋️‍♂️ Train 4 times per week"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="🗓 Looking ahead to June"
                    content={[
                        {spans: [], text: "For months I’ve been telling myself to takes things a little bit easier. So from June, I’ve decided I’m going to do that.", type: "paragraph"},
                        {spans: [], text: "I managed to book a new NextJS project from a lead who first reached out to me in October (he’s been reaching out ever since but I’ve always been too busy).", type: "paragraph"},
                        {spans: [], text: "From June until the end of August, I’ll be spending 3 days a week working on this project with their dev team. Outside of that, I’m not rushing to take on any new work (although I suspect I’ll have some work from existing clients).", type: "paragraph"},
                        {spans: [], text: "The free days I don’t have booked in, I will likely spend working on my own project(s) or spend that time with my family – which was the flexibility I really wanted from freelancing.", type: "paragraph"},
                    ]}
                />
                <ContentBlock
                    title="🥅 June goals"
                    checkList={[
                        {complete: false, text: "🚀 Launch my new business website"},
                        {complete: false, text: "📸 Take 3 photos a week and edit them"},
                        {complete: false, text: "👨‍🍳 “Create” healthy snacks each week"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="💡 Freelance tip of the month"
                    quote="Believe in yourself, your work and what you’re charging! Your mindset can either make or break the success of your freelance career or anything else for that matter."
                />
                <ContentBlock
                    title="💰 Earnings for the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 867,
                            width: 1396,
                        },
                        url: 'https://images.prismic.io/robsimpson/f9174af3-3755-466f-b0bc-160b31347196_may-2021-income.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Income: £7.9k", type: "paragraph"},
                    ]}
                    contentSize="large"
                />
                <ContentBlock
                    title="📦 Product of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/f0555b9b-2e55-4e97-b3c9-9e9058aefc84_ming-arailia.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Ming Aralia", type: "heading4"},
                        {spans: [], text: "I had the urge to buy a new plant this month (I currently have around 38 in my house). But I wanted something that was noticeably different to my others, so I landed on this Ming Aralia from Foli8. I also got a small Fiddle Leaf Fig from B&Q – which is quite a fussy plant, it wasn’t too expensive so I’m going to see if I can keep it alive.", type: "paragraph"},
                    ]}
                    buttonText="View product"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://foli8.co.uk/collections/houseplants/products/ming-aralia",
                    }}
                />
                <ContentBlock
                    title="📙 Book of the month"
                    image={{
                        alt: '',
                        dimensions: {
                            height: 3,
                            width: 4,
                        },
                        url: 'https://images.prismic.io/robsimpson/78b99e6d-ec97-4853-b8dd-91fbc3c8801c_book-plant-therapy.png?auto=compress,format'
                    }}
                    content={[
                        {spans: [], text: "Plant Therapy by Dr Katie Cooper", type: "heading4"},
                        {spans: [], text: "I came across this book whilst visiting The Lost Gardens Heligan on holiday. I’m both interested in health and plants so when I saw this book that covers the health benefits of plants I was very much interested. As a species that evolved from living in nature, it comes as no surprise that we are positively impacted by being in the presence of plants.", type: "paragraph"},
                    ]}
                    buttonText="View book"
                    buttonUrl={{
                        link_type: "Web",
                        url: "https://www.amazon.co.uk/Plant-Therapy-improve-emotional-wellbeing/dp/1784883522/",
                    }}
                />
            </div>
        </Shell>
    );
}

export default NewsletterIssue6;
